import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import { HomeView } from "./new_components/HomeView/HomeView";
import { DetailView } from "./new_components/DetailView/DetailView";
import { BlogListView } from "./new_components/BlogListView/BlogListView";
import { BlogArticleView } from "./new_components/BlogArticleView/BlogArticleView";
import { MusicView } from "./new_components/MusicView/MusicView";
import { EngView } from "./new_components/EngView/EngView";
import { ArtView } from "./new_components/ArtView/ArtView";
import { EngArticleView } from "./new_components/EngArticleView/EngArticleView";
import { ArtGalleryView } from "./new_components/ArtGalleryView/ArtGalleryView";

const BackgroundWrapper: React.FunctionComponent<{
  light: boolean;
  children: React.ReactNode;
}> = ({ light, children }) => {
  return (
    <div
      className={`background-wrapper${light ? " light-mode" : " dark-mode"}`}
    >
      {children}
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "*",
    element: (
      <BackgroundWrapper light={false}>
        <HomeView></HomeView>
      </BackgroundWrapper>
    ),
  },
  {
    path: "/blog",
    element: (
      <BackgroundWrapper light>
        <DetailView>
          <BlogListView />
        </DetailView>
      </BackgroundWrapper>
    ),
  },
  {
    path: "/blog/:blogSlug/*",
    element: (
      <BackgroundWrapper light>
        <DetailView>
          <BlogArticleView />
        </DetailView>
      </BackgroundWrapper>
    ),
  },
  {
    path: "/engineering",
    element: (
      <BackgroundWrapper light>
        <DetailView>
          <EngView />
        </DetailView>
      </BackgroundWrapper>
    ),
  },
  {
    path: "/engineering/:engineeringSlug/*",
    element: (
      <BackgroundWrapper light>
        <DetailView>
          <EngArticleView />
        </DetailView>
      </BackgroundWrapper>
    ),
  },
  {
    path: "/art/*",
    element: (
      <BackgroundWrapper light>
        <DetailView>
          <ArtView />
        </DetailView>
      </BackgroundWrapper>
    ),
  },
  {
    path: "/art/:artSlug/*",
    element: (
      <BackgroundWrapper light>
        <DetailView>
          <ArtGalleryView />
        </DetailView>
      </BackgroundWrapper>
    ),
  },
  {
    path: "/music/*",
    element: (
      <BackgroundWrapper light>
        <DetailView>
          <MusicView />
        </DetailView>
      </BackgroundWrapper>
    ),
  },
  {
    path: "/gallery/:gallerySlug/*",
    element: (
      <BackgroundWrapper light>
        <DetailView>
          <ArtGalleryView />
        </DetailView>
      </BackgroundWrapper>
    ),
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
