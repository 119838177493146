import React from "react";
import "./DetailViewTitleNav.css";
import { HomeViewTitleBox } from "../HomeViewTitleBox/HomeViewTitleBox";
import { Link } from "react-router-dom";

export const DetailViewTitleNav: React.FunctionComponent = () => {
  return (
    <div className="detail-view-title-nav">
      <div className="detail-view-sticky-nav">
        <HomeViewTitleBox showContent={false} lightBackground />
        <div className="title-nav-links">
          <p>
            <Link to="/blog">writing</Link>
          </p>
          <p>
            <Link to="/engineering">engineering & design</Link>
          </p>
          <p>
            <Link to="/art">photography & art</Link>
          </p>
          <p>
            <Link to="/music">music</Link>
          </p>
        </div>
      </div>
    </div>
  );
};
