import parse from "html-react-parser";

export const parseHTML = (html: string) => {
  if (!html) return null;

  return parse(html, { replace: (node) => {} });
};

export const useHTMLParser = (html: string) => {
  return parseHTML(html);
};
