import React from "react";
import { useCosmicLoader } from "../useCosmicLoader";
import { useHTMLParser } from "../useHTMLParser";
import { HomeViewBlogPost } from "./HomeViewBlogPost";
import { Link } from "react-router-dom";

export const HomeViewBlog: React.FunctionComponent = () => {
  const { data } = useCosmicLoader({
    fetchKey: "fetch-blog-page",
    contentType: "blog-page",
  });

  const { data: blogPosts } = useCosmicLoader({
    fetchKey: "fetch-blog-posts",
    contentType: "blog-posts",
  });

  const content = useHTMLParser(data?.objects[0].content);

  return (
    <div>
      <Link to="/blog">
        <h2>{data?.objects[0].title}</h2>
      </Link>
      <div>
        {blogPosts?.objects.map((post, i) => (
          <HomeViewBlogPost key={i} post={post}></HomeViewBlogPost>
        ))}
      </div>
    </div>
  );
};
