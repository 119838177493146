import React from "react";
import { useCosmicLoader } from "../useCosmicLoader";
import { HomeViewPhotoSpread } from "./HomeViewPhotoSpread";
import { Link } from "react-router-dom";

export const HomeViewPhotoArt: React.FunctionComponent = () => {
  const { data } = useCosmicLoader({
    fetchKey: "fetch-art-page",
    contentType: "art-page",
  });

  return (
    <div>
      <Link to="/art">
        <h2>{data?.objects[0].title}</h2>
      </Link>
      <HomeViewPhotoSpread />
    </div>
  );
};
