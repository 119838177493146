import React from "react";
import "./HomeViewBlog.css";
import { CosmicData } from "../CosmicTypes";
import { parseHTML } from "../useHTMLParser";
import { Link } from "react-router-dom";

interface HomeViewBlogPostProps {
  post: CosmicData<"blog-posts">["objects"][number];
}

export const HomeViewBlogPost: React.FunctionComponent<
  HomeViewBlogPostProps
> = ({ post }) => {
  return (
    <div className="blog-post">
      <Link to={`/blog/${post.slug}`}>
        <h3>{post.title}</h3>
      </Link>
      <p className="subheading">{post.metafields.date?.value}</p>
      <div>{parseHTML(post.metafields.deck?.value)}</div>
    </div>
  );
};
