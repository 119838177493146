import React from "react";
import { useCosmicLoader } from "../useCosmicLoader";
import { Link } from "react-router-dom";
import { parseHTML } from "../useHTMLParser";

export const HomeViewMusic: React.FunctionComponent = () => {
  const { data } = useCosmicLoader({
    fetchKey: "fetch-music-page",
    contentType: "music-page",
  });

  return (
    <div>
      <Link to="/music">
        <h2>{data?.objects[0].title}</h2>
      </Link>
      <div>{parseHTML(data?.objects[0]?.metafields.home_page_blurb.value)}</div>
    </div>
  );
};
