import React from "react";
import { parseHTML } from "../useHTMLParser";
import { Link } from "react-router-dom";

interface BlogListItemProps {
  title: string;
  slug: string;
  content: string;
  dateString: string;
}

export const BlogListItem: React.FunctionComponent<BlogListItemProps> = ({
  title,
  slug,
  content,
  dateString,
}) => {
  return (
    <div>
      <Link to={`/blog/${slug}`}>
        <h2>{title}</h2>
      </Link>
      <time>{dateString}</time>
      <div>{parseHTML(content)}</div>
    </div>
  );
};
