import React from "react";
import { useCosmicLoader } from "../useCosmicLoader";
import { parseHTML } from "../useHTMLParser";
import { ArtViewPhotoSpread } from "./ArtViewPhotoSpread";

export const ArtView: React.FunctionComponent = () => {
  const { data: photoData } = useCosmicLoader({
    fetchKey: "fetch-photo",
    contentType: "art-page",
  });

  return (
    <div>
      <h2>{photoData?.objects[0]?.title}</h2>
      <div>{parseHTML(photoData?.objects[0]?.content)}</div>
      <ArtViewPhotoSpread />
    </div>
  );
};
