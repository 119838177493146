import React from "react";
import "./HomeView.css";
import { HomeViewTitleBox } from "../HomeViewTitleBox/HomeViewTitleBox";
import { HomeViewPhotoArt } from "../HomeViewPhotoArt/HomeViewPhotoArt";
import { HomeViewEngineering } from "../HomeViewEngineering/HomeViewEngineering";
import { HomeViewBlog } from "../HomeViewBlog/HomeViewBlog";
import { HomeViewMusic } from "../HomeViewMusic/HomeViewMusic";

export const HomeView: React.FunctionComponent = () => {
  return (
    <div className="home-layout-columns">
      <HomeViewTitleBox showContent lightBackground={false} />
      <div className="content-grid">
        <div className="blog-container">
          <HomeViewBlog />
        </div>
        <div className="photo-art-container">
          <HomeViewPhotoArt />
        </div>
        <div className="music-container">
          <HomeViewMusic />
        </div>
        <div className="engineering-container">
          <HomeViewEngineering />
        </div>
      </div>
    </div>
  );
};
