import React from "react";
import "./ArtGalleryView.css";
import { useCosmicLoader } from "../useCosmicLoader";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { useParams } from "react-router-dom";

export const ArtGalleryView: React.FunctionComponent = () => {
  const { gallerySlug } = useParams();

  const { data: photogroupsData } = useCosmicLoader({
    fetchKey: "fetch-photo-groups",
    contentType: "photo-groups",
  });

  const photogroup = photogroupsData?.objects.find(
    (pg) => pg.slug === gallerySlug
  );

  const photos = photogroup?.metafields.images.value.map((image, i) => (
    <PhotoView src={image.src} key={i}>
      <img
        alt={image.alt}
        src={`${image.src}?auto=format,compress&w=400`}
        width="200px"
      ></img>
    </PhotoView>
  ));

  return <PhotoProvider>{photos}</PhotoProvider>;
};
