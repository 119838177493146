import React from "react";
import "./ArtView.css";
import { useCosmicLoader } from "../useCosmicLoader";
import Masonry from "react-masonry-component";
import { ResponsiveMasonry } from "react-responsive-masonry";

interface PhotogroupTileProps {
  title: string;
  imgix_url: string;
  slug: string;
}

const PhotogroupTile: React.FunctionComponent<PhotogroupTileProps> = ({
  title,
  imgix_url,
  slug,
}) => {
  return (
    <div className="art-view-photogroup-tile">
      <a href={`/gallery/${slug}`}>
        <img
          alt={`preview for ${title}`}
          src={`${imgix_url}?auto=format,compress&w=250`}
        ></img>
      </a>
    </div>
  );
};

export const ArtViewPhotoSpread: React.FunctionComponent = () => {
  const { data: photogroupsData } = useCosmicLoader({
    fetchKey: "fetch-photo-groups",
    contentType: "photo-groups",
  });

  const photogroups = photogroupsData?.objects
    ?.reverse()
    .map((group, i: number) => {
      const previewPhoto = group.metafields.preview_photo;

      return (
        <PhotogroupTile
          key={i}
          title={group.title}
          imgix_url={previewPhoto.imgix_url}
          slug={group.slug}
        ></PhotogroupTile>
      );
    });

  return (
    <div className="masonry-container">
      <ResponsiveMasonry>
        {/* @ts-ignore */}
        <Masonry
          gutter="4px"
          options={{ transitionDuration: "0.2s", fitWidth: true }}
        >
          {photogroups}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};
