import React, { useMemo } from "react";
import { useCosmicLoader } from "../useCosmicLoader";
import { BlogListItem } from "./BlogListItem";

export const BlogListView: React.FunctionComponent = () => {
  const { data: blogPageData } = useCosmicLoader({
    fetchKey: "fetch-blog-page",
    contentType: "blog-page",
  });

  const { data: blogPostsData } = useCosmicLoader({
    fetchKey: "fetch-blog-posts",
    contentType: "blog-posts",
  });

  const blogPosts = useMemo(() => {
    const posts = blogPostsData?.objects?.map((obj, i) => {
      return (
        <BlogListItem
          key={i}
          title={obj.title}
          slug={obj.slug}
          content={obj.content}
          dateString={obj.metafields.date.value}
        ></BlogListItem>
      );
    });
    return posts;
  }, [blogPostsData?.objects]);

  return (
    <div>
      <h1>{blogPageData?.objects[0]?.title}</h1>
      {blogPosts}
    </div>
  );
};
