import React from "react";
import "./DetailView.css";
import { DetailViewTitleNav } from "../DetailViewTitleNav/DetailViewTitleNav";

interface DetailViewProps {
  children?: React.ReactNode;
}

export const DetailView: React.FunctionComponent<DetailViewProps> = ({
  children,
}) => {
  return (
    <div className="detail-view">
      <DetailViewTitleNav></DetailViewTitleNav>
      <div className="detail-view-content">{children}</div>
    </div>
  );
};
