import React from "react";
import { useParams } from "react-router-dom";
import { useCosmicLoader } from "../useCosmicLoader";
import { parseHTML } from "../useHTMLParser";

export const BlogArticleView: React.FunctionComponent = () => {
  const { blogSlug } = useParams();

  const { data: blogPosts } = useCosmicLoader({
    fetchKey: "fetch-blog-posts",
    contentType: "blog-posts",
  });

  const blogPost = blogPosts?.objects.find((post) => post.slug === blogSlug);

  return (
    <article>
      <h1>{blogPost?.title}</h1>
      <h3>{blogPost?.metafields.date?.value}</h3>
      {parseHTML(blogPost?.content ?? "")}
    </article>
  );
};
