import useSWR from "swr";
import Cosmic from "cosmicjs";
import { ContentType, CosmicData } from "./CosmicTypes";

const api = Cosmic();
const bucket = api.bucket({
  slug: "jeff-demanche-production",
  read_key: "bGPRjFfPJqkrOKY0y1IZw9oaf4kxhrgJZeIDBeonVGlJxs26NW",
});

export const useCosmicLoader = <T extends ContentType>({
  fetchKey,
  contentType,
  slug,
}: {
  fetchKey: string;
  contentType: T;
  slug?: string;
}) => {
  const { data, error } = useSWR(fetchKey, async () => {
    const data = await bucket.objects
      .find({ type: contentType, slug })
      .props("title,slug,content,metafields");
    return data;
  });

  const mappedMetafieldObjects = data?.objects?.map((object: any) => {
    const metafieldMap: any = {};
    object.metafields.forEach((m: any) => (metafieldMap[m.key] = m));
    return {
      ...object,
      metafields: metafieldMap,
    };
  });

  let dataWithMappedMetafields = data;
  if (data?.objects)
    dataWithMappedMetafields = { ...data, objects: mappedMetafieldObjects };

  return { data: dataWithMappedMetafields, error } as {
    data: CosmicData<T>;
    error: any;
  };
};
