import React from "react";
import "./HomeViewPhotoSpread.css";
import { Link } from "react-router-dom";

interface PhotogroupTileProps {
  title: string;
  imgix_url: string;
  slug: string;
}

export const HomeViewPhotogroupTile: React.FunctionComponent<
  PhotogroupTileProps
> = ({ title, imgix_url, slug }) => {
  return (
    <div className="photogroup-tile">
      <Link to={`/gallery/${slug}`}>
        <img
          alt={`preview for ${title}`}
          src={`${imgix_url}?auto=format,compress&w=250`}
        ></img>
      </Link>
    </div>
  );
};
