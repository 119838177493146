import React from "react";
import { useCosmicLoader } from "../useCosmicLoader";
import { parseHTML } from "../useHTMLParser";
import { Link } from "react-router-dom";

export const HomeViewEngineering: React.FunctionComponent = () => {
  const { data } = useCosmicLoader({
    fetchKey: "fetch-engineering-page",
    contentType: "engineering-page",
  });

  const content = parseHTML(data?.objects[0].metafields.home_page_blurb.value);

  return (
    <div>
      <Link to="/engineering">
        <h2>{data?.objects[0].title}</h2>
      </Link>
      <div>{content}</div>
    </div>
  );
};
