import React from "react";
import "./HomeViewPhotoSpread.css";
import { useCosmicLoader } from "../useCosmicLoader";
import { HomeViewPhotogroupTile } from "./HomeViewPhotogroupTile";
import Masonry from "react-masonry-component";

export const HomeViewPhotoSpread: React.FunctionComponent = () => {
  const { data: photogroupsData } = useCosmicLoader({
    fetchKey: "fetch-photo-groups",
    contentType: "photo-groups",
  });

  const photogroups = photogroupsData?.objects
    ?.reverse()
    .map((group, i: number) => {
      const previewPhoto = group.metafields.preview_photo;

      return (
        <HomeViewPhotogroupTile
          key={i}
          title={group.title}
          imgix_url={previewPhoto.imgix_url}
          slug={group.slug}
        ></HomeViewPhotogroupTile>
      );
    })
    .splice(0, 4);

  return (
    <div className="photo-previews-spread">
      {/* @ts-ignore */}
      <Masonry
        elementType="div"
        options={{
          containerStyle: { position: "relative", top: 0, width: "700px" },
          transitionDuration: 0,
          columnWidth: 250,
          fitWidth: true,
          gutter: 4,
        }}
      >
        {photogroups}
      </Masonry>
    </div>
  );
};
