import React from "react";
import { useCosmicLoader } from "../useCosmicLoader";
import { parseHTML } from "../useHTMLParser";

export const MusicView: React.FunctionComponent = () => {
  const { data: musicData } = useCosmicLoader({
    fetchKey: "fetch-music",
    contentType: "music-page",
  });

  return (
    <article>
      <h2>{musicData?.objects[0]?.title}</h2>
      <div>{parseHTML(musicData?.objects[0]?.content)}</div>
    </article>
  );
};
