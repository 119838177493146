import React, { useMemo } from "react";
import "./HomeViewTitleBox.css";
import { useCosmicLoader } from "../useCosmicLoader";
import { useHTMLParser } from "../useHTMLParser";
import { Link } from "react-router-dom";

interface HomeViewTitleBoxProps {
  showContent: boolean;
  lightBackground: boolean;
}

export const HomeViewTitleBox: React.FunctionComponent<
  HomeViewTitleBoxProps
> = ({ showContent, lightBackground }) => {
  const { data: socialsData, error: socialsError } = useCosmicLoader({
    fetchKey: "fetch-socials",
    contentType: "social-links",
  });

  const { data: titleData, error: titleError } = useCosmicLoader({
    fetchKey: "fetch-title",
    contentType: "title",
  });

  const title = titleData?.objects[0]?.metafields.text.value;
  const content = titleData?.objects[0].content;

  const parsedContent = useHTMLParser(content);

  const socialLinks = useMemo(() => {
    return socialsData?.objects.map((link, i: number) => {
      return (
        <a
          className={`social-link${lightBackground ? " light-background" : ""}`}
          key={i}
          target="_blank"
          rel="noreferrer"
          href={link.metafields.link.value}
        >
          <img
            src={link.metafields.icon.url}
            alt={link.metafields.alt_text.value}
          ></img>
        </a>
      );
    });
  }, [lightBackground, socialsData?.objects]);

  return (
    <div className="title-box-wrapper">
      <div className={`title-box${lightBackground ? " light-background" : ""}`}>
        <h1>
          <Link to="/">{title}</Link>
        </h1>
        <div className="social-links">{socialLinks}</div>
        {showContent && <div className="description">{parsedContent}</div>}
      </div>
    </div>
  );
};
