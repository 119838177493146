import React from "react";
import { useParams } from "react-router-dom";
import { useCosmicLoader } from "../useCosmicLoader";
import { parseHTML } from "../useHTMLParser";

export const EngArticleView: React.FunctionComponent = () => {
  const { engineeringSlug } = useParams();

  const { data: detailPages } = useCosmicLoader({
    fetchKey: "fetch-detail-pages",
    contentType: "detail-pages",
  });

  const detailPage = detailPages?.objects.find(
    (page) => page.slug === engineeringSlug
  );

  return (
    <article className="eng-article">
      {parseHTML(detailPage?.content ?? "")}
    </article>
  );
};
